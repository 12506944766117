
<script setup lang="ts">
    import { ref, watch, onMounted, onBeforeUnmount, computed, useSlots } from "vue";

    import { type DataObject } from "o365-dataobject";
    import { $t } from "o365-utils";
    import { context } from "o365-modules";

    import MContainer from "./components.Container.vue";
    import MContent from "./components.Content.vue";
    import MSearch from "./components.Search.vue";
    import MFilterPills from "./components.filter.FilterPills.vue";
    import MFilterSheets from "./components.filter.FilterSheets.vue";
    import MRowCount from "./components.RowCount.vue";
    import MActionSheet from "./components.ActionSheet.vue";
    import MActionSheetBtn from "./components.ActionSheetBtn.vue";
    import MFolderView from "./Register.FolderView.FolderView.vue";

    import Filter from "./controls.filter.Filter.js";

    export interface IProps {
        dataObject: DataObject;
        filter: Filter; 
        title?: string;
        hideFolderView?: boolean;
    };

    const props = defineProps<IProps>();
    const slots = useSlots();

    const showMenu = ref<boolean>(false);
    const folderView = ref<boolean>(false);
    
    // the var using for block folderGroups dropdown. If value is more than 1 - it's blocked 
    const folderLevel = ref<number>(0);

    function update() {
        // commented cause this line breaks scope-items app load with filter 
        props.filter.applyToFilterObject();

        props.dataObject.recordSource.whereClause = props.filter.getFilterItemsWhereClause() ?? "";
        props.dataObject.recordSource.setSortOrder(props.filter.getSortOrder());
        
        if (props.filter.savedFilter) {
            props.filter.removeSavedFilter();
        }

        props.dataObject.load();
    }

    function onContext() {
        props.dataObject.load();
    }

    // apply sortOrder without needing to clear filter object
    // as it's done on the update function 
    const applySortOrder = (): void => {
        props.dataObject.recordSource.setSortOrder(props.filter.getSortOrder());
        props.dataObject.load();
    };

    const setFolderLevel = (level: number): number => folderLevel.value = level; 

    const toggleView = async () => {
        folderView.value = !folderView.value;
        folderLevel.value = 0;

        if (!folderView.value) {
            // refresh filters if were applied. 
            if (props.dataObject.recordSource.groupByFilter === '') {
                return;
            } else {
                props.dataObject.recordSource.groupByFilter = '';
                props.dataObject.state.isLoaded = false;
                await props.dataObject.load();
            }
        } 
    }

    watch(() => props.filter.search, () => {
        update();
    });

    onMounted(() => {
        update();

        // set a saved filter on init if there's one in local storage.
        if(!props.dataObject.filterObject.activeFilter) {
            if (props.filter.storage.savedFilter && !props.filter.savedFilter) {
                props.filter.selectSavedFilter(props.filter.storage.savedFilter)
                    .then((funcCallResult: any) => { });
            }
        }

        props.filter.on("update", update);
        context.on("Change", onContext);
    });

    onBeforeUnmount(() => {
        props.filter.off("update", update);
        context.off("Change", onContext);
    });
// && !dataObject.state.isLoaded
</script>


<template>
    <MContainer :showSpinner="props.dataObject.state.isLoading && (!props.dataObject.state.isLoaded || !folderView)">
        <slot name="header" />

        <!-- title -->
        <template v-if="!slots.header">
            <div class="mt-3 px-3 d-flex">
                <div class="flex-grow-1">
                    <slot name="title">
                        <div class="font-4 fw-medium text-black">
                            {{ title || "Title is missing" }}
                        </div>
                    </slot>
                </div>
                <div class="flex-shrink-0 d-flex align-items-center gap-2">
                    <div>
                        <MRowCount :data-object="dataObject" />
                    </div>
                    <template v-if="!hideFolderView || slots.actions">
                        <div>
                            <a
                                class="d-flex justify-content-center align-items-center text-black"
                                style="width: 1.5em; height: 1.5em; margin: -0.75em; padding: 1.5em;"
                                @click="showMenu = true"
                            >
                                <i class="bi bi-three-dots-vertical font-4" />
                            </a>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        
        <template v-if="folderView">
            <MFolderView @setFolderLevel = "setFolderLevel"
                :folderLevel="folderLevel" 
                :dataObject="dataObject" >
                <template #card="{ row }">
                    <slot :row = "row" name="card"/>
                </template>
            </MFolderView>
        </template>
        <template v-else>
            <template v-if="!slots.header">
                <div class="mb-1">
                    <!-- search -->
                    <div class="mt-2 mx-3">
                        <MSearch class="flex-1" v-model="filter.search" :filter="filter" />
                    </div>

                    <!-- pills -->
                    <div class="mt-2">
                        <MFilterPills class="mb-2 px-3" 
                            :filter="filter" 
                            :data-object="dataObject" />
                    </div>
                </div>
            </template>

            <!-- cards -->
            <template v-if="dataObject.state.isLoaded">
                <MContent :dataObject="dataObject">
                    <div class="px-3 pb-3">
                        <div class="row g-2 g-lg-3">
                            <template v-for="row in dataObject.data">
                                <div class="col-12 col-lg-6 col-xxl-4">
                                    <slot name="card" :row="row">
                                        {{ "row " + row.ID }}
                                    </slot>
                                </div>
                            </template>
                        </div>
                    </div>
                </MContent>
            </template>
        </template>
    </MContainer>

    <MFilterSheets :dataObject = "dataObject" :filter="filter" @applySortOrder = "applySortOrder"  />

    <MActionSheet v-model="showMenu">
        <template v-if="!hideFolderView">
            <MActionSheetBtn :text="folderView ? $t('Card View') : $t('Folder View')" @click="toggleView" />
        </template>
        <slot name="actions" />
    </MActionSheet>
</template>
